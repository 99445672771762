<template>
  <section>
    <BeUserFilter />
    <Preloader v-if="callingAPI" />
    <BeUserListTable
      :be-users="beUsers"
      :total-count="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import BeUserFilter from '@/components/filter/FilterBeUser'
import BeUserListTable from '@/components/beUser/BeUserListTable'
import BeUserFilterModel from '@/model/beUser/BeUserFilter'

export default {
  name: 'BeUserListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    beUsers () {
      return this.$store.getters['beUser/list']
    },
    totalCount () {
      return this.$store.getters['beUser/totalCount']
    },
    page () {
      return this.$store.getters['beUser/page']
    }
  },
  components: {
    BeUserListTable,
    Preloader,
    BeUserFilter
  },
  methods: {
    getBeUsers () {
      this.$store.dispatch('beUser/fetch')
    },
    setPageAndGetRecords (page) {
      this.$store.commit('beUser/setPage', page)
      this.getBeUsers()
    }
  },
  created () {
    this.$store.commit('beUser/setFilter', this._.cloneDeep(BeUserFilterModel))
    this.$store.commit('beUser/setPage', 1)
    this.getBeUsers()
  }
}
</script>
