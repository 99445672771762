<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="beUsers"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    table-css-class="be-user-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.userId"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.givenName"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.familyName"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.email"
      >
        <template #after>
          <span
            v-if="item.localLogin"
            class="local-login-badge">
            {{ $t('beUser.localLogin') }}
          </span>
        </template>
      </TitleColumn>
    </template>
    <template #actions="{ item }">
      <ButtonTableAction
        v-if="isEditButtonVisible"
        icon="edit"
        @click="editButtonClick(item)"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'BeUserListTable',
  props: {
    beUsers: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    ButtonTableAction,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    isEditButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_BACKEND_USER_PERMISSIONS.editButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    tableHeaders () {
      return [
        { name: this.$t('beUser.id') },
        { name: this.$t('beUser.firstName') },
        { name: this.$t('beUser.lastName') },
        { name: this.$t('beUser.login') },
        { name: this.$t('actions') }
      ]
    }
  },
  methods: {
    editButtonClick (item) {
      this.$router.push(`beUser/${item.userId}/edit`)
    }
  }
}
</script>
<style lang="scss">
  .be-user-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(250px) rem(120px) rem(120px) auto max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(300px) rem(250px) rem(250px) auto rem(70px);
      }
    }
    .local-login-badge {
      @include font(400 14px "Roboto");
      background-color: #1e88e5;
      color: #ffffff;
      padding: rem(3px) rem(10px);
      margin-left: rem(8px);
      border-radius: rem(6px);
    }
  }
</style>
