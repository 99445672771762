<template>
  <AbstractFilter
    :on-filter="filterBeUsers"
    :on-filter-reset="resetFilters"
    :show-create-button="false"
    filter-panel-class="filter-panel-be-user"
  >
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('beUser.id')"
    />
    <Input
      v-model="filter.email"
      id="filter_email"
      :label="$t('beUser.login')"
    />
    <Input
      v-model="filter.firstName"
      id="filter_firstName"
      :label="$t('beUser.firstName')"
    />
    <Input
      v-model="filter.lastName"
      id="filter_lastName"
      :label="$t('beUser.lastName')"
    />
    <Checkbox
      id="fullScreen"
      v-model="filter.showLocalUsers"
      :label="$t('beUser.showLocalUsers')"
    />
  </AbstractFilter>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import Checkbox from '@/components/form/Checkbox'
import BeUserFilterModel from '@/model/beUser/BeUserFilter'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'BeUserFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(BeUserFilterModel),
      enabledOptions: [
        {
          id: 'none',
          title: '---'
        },
        {
          id: 1,
          title: this.$t('beUser.yes')
        },
        {
          id: 0,
          title: this.$t('beUser.no')
        }
      ],
      advancedFilter: false
    }
  },
  components: {
    AbstractFilter,
    Input,
    Checkbox
  },
  methods: {
    filterBeUsers () {
      this.$store.commit('beUser/setPage', 1)
      this.$store.commit('beUser/setFilter', this.filter)
      this.$store.dispatch('beUser/fetch')
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    resetFilters () {
      this.filter = this._.cloneDeep(BeUserFilterModel)
      this.filterBeUsers()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['beUser/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-be-user {
  @include bp(7) {
    grid-template-columns: 1fr 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.7fr;
  }
}
</style>
